import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout, { PageColors } from '../components/layout';
import FeaturedServiceRemoteUXT from '../components/feat-srv-remote-uxt';
import ContactWidget from '../components/contact-widget';
import Footer from '../components/footer';
import './index.scss';

import uxologyGroupLogo from '../images/uxology-group-logo.png';
import uxgDetailedLogo from '../images/uxg-detailed-logo.png';
import warningIcon from '../images/warning-icon.svg';
import brandCloud from '../images/brand-cloud.png';
import uxgSlugGreen from '../images/uxg-slug-green.png';
import lightBulb from '../images/light-bulb.png';
import uxgBenefits from '../images/uxg-benefits.png';
import overviewContactLeft from '../images/overview-contact-logo-left.png';
import overviewContactRight from '../images/overview-contact-logo-right.png';
import { ContactUxology } from '../components/contact-uxology';
import { HeroSection } from '../components/hero-section';

export default class IndexPage extends React.Component {
	render() {
		return (
			<StaticQuery
				query={graphql`
					{
						brands: file(relativePath: { regex: "/brand-cloud.png/" }) {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH)
							}
						}
						bulb: file(relativePath: { regex: "/lightbulb.png/" }) {
							childImageSharp {
								gatsbyImageData(width: 460, layout: CONSTRAINED)
							}
						}
						benefits: file(relativePath: { regex: "/ux-benefits.png/" }) {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH)
							}
						}
						comp: file(relativePath: { regex: "/uxg-competency.png/" }) {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH)
							}
						}
					}
				`}
				render={(data) => this._render(data)}
			/>
		);
	}

	_render(/* data */) {
		// console.log("IndexPage render:", data);
		return (
			<Layout page="index" location={this.props.location}>
				<div className="page-index">
					<HeroSection
						words={['INSIGHTS', 'RECOMMENDATIONS', 'OUTCOMES']}
						bgColor={PageColors.index}
					>
						We take the guesswork out of creating fantastic experiences by
						leveraging research to solve product and design problems.
					</HeroSection>
					<section className="sec2-brands">
						<div className="content container">
							<div className="logos">
								<img src={uxgSlugGreen} alt="Uxg Slug" />
								<img src={uxgSlugGreen} alt="Uxg Slug" />
							</div>
							<h1>What do these major brands have in common?</h1>
							<img src={brandCloud} alt="Brand cloud" className="brand-cloud" />
							<div className="brands-section-description">
								<h2>
									Top-notch consultants at The UXology Group have performed User
									Experience ("UX") work for each of these innovative
									businesses.
								</h2>
								<p>
									We know that the most successful organizations on earth have
									figured out that it makes a lot of sense to embrace UX
									Research, because we’ve helped them do it. By blending the
									arts of consulting and knowledge elicitation with the sciences
									of psychology, biometrics and human behavior, we can help you
									evaluate and improve UX in ways you probably can’t do alone.
								</p>
							</div>
						</div>
					</section>

					<section className="sec2-overview">
						<div className="content container">
							<img
								className="header-logo"
								src={uxgDetailedLogo}
								alt="UXology logo"
							/>
							<h1>OVERVIEW</h1>
							<div className="overview-content-wrapper">
								<img
									className="background-logo"
									src={uxologyGroupLogo}
									alt="UXology logo"
								/>
								<p style={{ lineHeight: '175%' }}>
									<img
										className="inline-logo"
										src={uxologyGroupLogo}
										alt="UXology logo"
									/>
									Let's face it. This rapidly expanding and highly
									cross-functional field, known as User Experience, often loses
									its roots in the fast-paced business world of aggressive
									timelines and trendy buzzwords. Many product teams are just
									talking about their users in corporate echo-chambers and
									blindly designing things based on vanity metrics masqueraded
									as quantitative research. They are failing to adhere to the
									core tenant of UX: "know thy user(s)." They are forgetting
									that it's usually far more insightful to learn about their
									users through direct observation vs. other so-called
									"research" methods. All the jargon and questionably-collected
									data in the world won't tell them what they really need to
									know about the UX they are responsible for delivering. This is
									why we focus on bringing only the most effective research
									methods to bear on solving their UX challenges. This is why we
									do what we do: UX isn't UX without research and we know how to
									do it right.
									<br />
									<br />
									Before going forward, let's establish some key definitions so
									we're all on the same page. Let's start with these:
									<br />
									<br />
									<strong>User</strong>: The person interacting with a product,
									system, or service.
									<br />
									<br />
									<strong>User Experience (UX)</strong>: UX refers to what
									people think and do when interacting with a product, system,
									or service.
									<br />
									<br />
									<strong>UX Research</strong>: The systematic investigation of
									User Experience to uncover opportunities for improvement and
									to inform design direction.
									<br />
									<br />
									<strong>UX Testing</strong>: A research method characterized
									by the observation of users while they interact with a
									product, system, or service (or a prototype thereof).
									<br />
									<br />
								</p>

								<p>
									The value of a superb User Experience (UX) is undeniable, from
									higher satisfaction, fewer "user errors," increased
									productivity and lower redesign costs to superior brand
									perception. But getting it right isn’t easy and product teams
									often lack the clear and actionable, unbiased,
									observation-based insights that UX Research provides. That’s
									where we come in.
									<br />
									<br />
									The UXology Group is 100% focused on providing product teams
									like yours with the insights that lead to more user-friendly
									products, systems, and services. Whether you want us to
									conduct exploratory research to uncover foundational user
									needs before building anything, are mid-flight through an
									aggressive development timeline, or have already launched, we
									will provide the correct research services for your situation.
									<br />
								</p>
							</div>
						</div>
					</section>
					<section className="sec-covid19-update">
						<div className="content container">
							<img
								className="header-logo"
								src={uxologyGroupLogo}
								alt="UXology logo"
							/>
							<div className="covid19-update-header">
								<div>
									<img
										className="warning-icon"
										src={warningIcon}
										alt="Warning Icon"
									/>
									<h5>COVID-19 Update:</h5>
								</div>
							</div>
							<p>
								In our upended new world, gripped by the Covid-19/Corona Virus
								Pandemic, social distancing and other preventive measures
								against infection have quickly become the norm. The UXology
								Group is making every effort to comply with and support safe
								preventive practices, as defined by the experts in the
								scientific, medical and governmental communities. In response to
								the current situation, in order to assist severely challenged
								businesses, The UXology Group is providing a 15% discount on our
								Remote UX Testing program. This regimen supports highly
								effective, contactless research sessions, for both participants
								and researchers, who need not be located in the same room, or
								even the same country. All contact is digital and remote. At
								this time of unprecedented upheaval and uncertainty, one of the
								most important positive and constructive actions that businesses
								can take, in order to rebuild and survive this Pandemic and pave
								the way for a positive future is to reopen and start operating
								again. The UXology Group is fully prepared to assist with your
								critical UX Research needs as you carefully consider the best
								ways to realign your products, systems and services in order to
								provide the best possible User Experience to your clients and
								users. Please contact us if you have any questions or we can be
								of assistance.
							</p>
							<ContactUxology
								page="index"
								topLabel="Contact"
								bottomLabel="The UXology Group"
							/>
							<p>
								If in-person research methodologies are absolutely required for
								a given project, we can make arrangements to have any UX
								Researchers and participants tested for Covid-19 within 24 hours
								of an in-person session. Further, we will enforce a mask at all
								times policy as well as expedite the entire process as much as
								possible, without adversely affecting the results. The UXology
								Group is obviously in no position to predict, guarantee or
								affect any particular outcome or danger level of Covid-19
								testing and/or any precautionary measures employed to protect
								against contracting the disease, however, we will take every
								reasonably available precaution to prevent such an occurrence.
							</p>
						</div>
					</section>
					<section className="sec3-bulb">
						<div className="content container">
							<img
								className="header-logo"
								src={uxologyGroupLogo}
								alt="UXology logo"
							/>
							<h1>Common Questions That We Answer For Clients</h1>
							<div className="row bulb-row">
								<div className="bulb-wrap">
									{/* <Img fluid={data.bulb.childImageSharp.fluid}/> */}
									<img
										src={lightBulb}
										alt="Lightbulb"
										style={{ width: '100%' }}
									/>
								</div>
								<div className="text-blobs">
									<div className="blob blob1">
										How well can people actually use our product/service? What
										frustrates them?
										<p>How can we solve these issues?</p>
									</div>
									<div className="blob blob2">
										What types of so-called "user-errors" are occurring? How can
										we shorten learning curves, increase satisfaction and
										improve user efficiency?
									</div>
									<div className="blob blob3">
										How easy is it for people to find what they are looking for
										on our website? In our app? In our stores? What can we do to
										improve these experiences?
									</div>
									<div className="blob blob4">
										Where are the pain points in our omni-channel journey and
										how can we solve them? How does our UX compare to the
										competition?
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="sec4-benefits">
						<div className="content container">
							<img
								className="header-logo"
								src={uxologyGroupLogo}
								alt="UXology logo"
							/>
							<h1>The Business Benefits Of Better UX</h1>
							<p>
								<img
									className="inline-logo"
									src={uxologyGroupLogo}
									alt="UXology logo"
								/>
								Quantifying the return-on-investment ("ROI") for UX, as with the
								overlapping fields of market research, customer experience, and
								design, is a complex and 100% contextual problem. It's possible
								to numerically measure the impact of UX Research in some cases,
								such as for digital products with highly granular analytics, but
								even those cases are rife with practical limitations and
								significantly lacking in scientific validity. As with everything
								in business, and in life, your results will vary. Every company
								and business problem is unique. That being said, there are
								patterns, and it's hard to argue with the qualitative benefits
								of identifying UX issues early and often, so that your
								organization can fix them to improve User Experience.
							</p>
							<img
								className="uxg-benefits-image"
								src={uxgBenefits}
								alt="Uxg Benefits"
							/>
						</div>
					</section>
					<FeaturedServiceRemoteUXT />
					<div className="sec5-contact-logos">
						<img src={overviewContactLeft} alt="UXG logo" />
						<img src={overviewContactRight} alt="UXG logo" />
					</div>
					<section className="sec6-contact">
						<div className="content container">
							<ContactWidget showSocial />
						</div>
					</section>
					<Footer page="index" location={this.props.location} />
				</div>
			</Layout>
		);
	}
}
