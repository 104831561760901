import React from 'react';

import './feat-srv-remote-uxt.scss';

import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css'; // Allows for server-side rendering.
import uxologyGroupLogo from '../images/uxology-group-logo.png';
import uxgNextSlide from '../images/uxg-next-slide.png';

export default class Section extends React.Component {
	constructor(props) {
		super(props);

		const options = {
			items: 1,
			nav: false,
			dots: true,
			rewind: true,
			autoplay: false,
		};

		const events = {
			onDragged(event) {},
			onChanged(event) {},
		};

		this.owlConfig = { options, events };
	}

	render() {
		return (
			<>
				<section className="sec5-service">
					<div className="content container">
						<img
							className="header-logo"
							src={uxologyGroupLogo}
							alt="UXology logo"
						/>
						<h1>
							Featured Service:
							<br />
							Remote UX Testing
						</h1>

						<OwlCarousel
							ref={(el) => {
								this.el = el;
							}}
							{...this.owlConfig}
						>
							<div className="process-item-wrap">
								<h2>1. Planning</h2>
								<div className="process-item row">
									<div className="col-xs-12 col-sm-6">
										<p>
											Remote UX testing is a simple, effective, and efficient
											way to evaluate digital products such as websites and
											software. The goal of these projects is to evaluate UX by
											observing what people think, say, and do while interacting
											with your digital product under the watchful eye of a
											professional researcher. Typically, seven to ten
											participants are involved in qualitative UX tests and more
											users may be involved for studies needing quantitative
											data too.
										</p>
									</div>
									<div className="col-xs-12 col-sm-6">
										<p>
											First, we host a remote kickoff meeting with you. In this
											call, we walk through the project objectives, approach,
											and timeline with your team to ensure alignment and
											understanding. After that, we will ask your team to
											demonstrate the product we'll be testing. We'll also put
											together a Research Outline &amp; Moderator Guide, which
											describe how the test is structured, for your feedback and
											review. Once finalized, these documents will be used to
											drive the collection of data during testing.
											<img
												src={uxgNextSlide}
												alt="Uxg next slide"
												onClick={() => this.el.next()}
											/>
										</p>
									</div>
								</div>
							</div>
							<div className="process-item-wrap">
								<h2>2. Recruiting</h2>
								<div className="process-item row">
									<div className="col-xs-12 col-sm-6">
										<p>
											Second, we start the recruiting process. This begins with
											creation of a screener, which contains all the criteria we
											need to find your target users for testing. After
											finalizing the screener, with inputs from your team, we'll
											use those demographic, behavioral, and attitudinal
											characteristics to find participants for the test.
										</p>
									</div>
									<div className="col-xs-12 col-sm-6">
										<p>
											The process of recruiting can take many forms. However,
											we'll typically tap our top-notch trusted network of
											agencies, which specialize in research recruiting, to find
											the right people to participate. Once identified and
											recruited, we will schedule each participant for their
											session and make sure they are ready for it.
											<img
												src={uxgNextSlide}
												alt="Uxg next slide"
												onClick={() => this.el.next()}
											/>
										</p>
									</div>
								</div>
							</div>
							<div className="process-item-wrap">
								<h2>3. Data Collection</h2>
								<div className="process-item row">
									<div className="col-xs-12 col-sm-6">
										<p>
											Third, we facilitate a series of interviews with the
											participants we recruited, often over one or two days, in
											what is also known as a{' '}
											<a href="https://www.usability.gov/how-to-and-tools/methods/usability-testing.html">
												qualitative user/usability
											</a>{' '}
											test. This is an enlightening, data-rich experience
											because observation is the most powerful form of research.
											Audio, video, and screen sharing are leveraged over a web
											conference so your team can watch the sessions live from
											anywhere. Sessions are also recorded so we can create
											video highlights for you that illustrate key findings.
										</p>
									</div>
									<div className="col-xs-12 col-sm-6">
										<p>
											Testing is facilitated by an expert moderator who knows
											how to elicit candid feedback from each participant. This
											moderator is supported by a team of usability
											professionals who leverage principles of design,
											psychology, and human behavior to call out issues that are
											causing UX problems. For instance, we flag design issues
											and shortcomings that cause ‘user error’, identify sources
											of confusion, unmet user needs, and otherwise pinpoint
											problems that trigger frustration, so you know what to fix
											to create a better experience.
											<img
												src={uxgNextSlide}
												alt="Uxg next slide"
												onClick={() => this.el.next()}
											/>
										</p>
									</div>
								</div>
							</div>
							<div className="process-item-wrap">
								<h2>4. Reporting Results</h2>
								<div className="process-item row">
									<div className="col-xs-12 col-sm-6">
										<p>
											Fourth, we will analyze all the data and present it as
											valuable insights in the form of written/presented
											reports. The first report, delivered within 48 hours of
											data collection, is a high level summary of findings. The
											second report includes detailed descriptions of UX issues
											combined with actionable strategic and tactical
											recommendations for how to improve your product, supported
											by screenshots, hard-hitting participant quotes, and video
											highlights (if desired).
										</p>
									</div>
									<div className="col-xs-12 col-sm-6">
										<p>
											Each report is carefully crafted to be straightforward and
											easily understood by your audience, no matter how diverse
											or specialized they are. At this point, clients are
											normally so thrilled with the discoveries that they want
											ongoing iterative UX testing to stay abreast of the
											ever-changing needs and expectations of the digital
											experience. Contact us to find out more!{' '}
										</p>
									</div>
								</div>
							</div>
						</OwlCarousel>
					</div>
				</section>
			</>
		);
	}
}
